import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { getRuntimeVariable } from "@pwa/lib/env";
import { useRouter } from "next/router";
import { FC, useCallback } from "react";

export const AuthProvider: FC<React.PropsWithChildren<unknown>> = ({
  children
}) => {
  const { replace } = useRouter();

  const onRedirectCallback = useCallback<
    (appState: AppState | undefined) => void
  >(
    (appState) => {
      replace(appState?.returnTo || "/");
    },
    [replace]
  );

  return (
    <Auth0Provider
      domain={getRuntimeVariable("PWA_BROWSER_AUTH_0_DOMAIN")}
      clientId={getRuntimeVariable("PWA_BROWSER_AUTH_0_CLIENT_ID")}
      audience={getRuntimeVariable("PWA_BROWSER_AUTH_0_AUDIENCE")}
      useRefreshTokens
      cacheLocation={
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        typeof window !== "undefined" && typeof window.Cypress !== "undefined"
          ? "localstorage"
          : "memory"
      }
      redirectUri={
        typeof window !== "undefined"
          ? `${getRuntimeVariable("PWA_BROWSER_APP_HOSTNAME")}/callback`
          : undefined
      }
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
