/* eslint-disable @typescript-eslint/ban-ts-comment */
import "@machineq/elements/css";

import { MQThemeProvider } from "@machineq/components";
import { ToastProvider } from "@machineq/elements";
import { SWRProvider } from "@pwa/features/api";
import { AuthProvider } from "@pwa/features/auth";
import type { CustomApp } from "@pwa/features/page";
import { usePWA } from "@pwa/features/pwa";
import { initializeSentry } from "@pwa/lib/sentry";
import { LazyMotion } from "framer-motion";
import React, { useEffect } from "react";

const lazyMotionFeatures = () =>
  import("@pwa/lib/motion/motion").then((mod) => mod.domAnimation);

initializeSentry();

const App: CustomApp = ({ Component, pageProps: appPageProps }) => {
  usePWA();

  /**
   * Delete the SSR rendered styles to reduce style bloat
   * We don't really need them if the client styled
   * components is going to take over for it
   */
  useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles && jssStyles.parentNode)
      jssStyles.parentNode.removeChild(jssStyles);
  }, []);

  return (
    <AuthProvider>
      <SWRProvider>
        <MQThemeProvider>
          <LazyMotion features={lazyMotionFeatures}>
            <ToastProvider>
              {Component.getLayout(<Component {...appPageProps} />, {
                ...appPageProps,
                ...Component.pageProps
              })}
            </ToastProvider>
          </LazyMotion>
        </MQThemeProvider>
      </SWRProvider>
    </AuthProvider>
  );
};

export default App;
